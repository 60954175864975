<template>
	<div>
		<header-main />
		<div class="page-admin">
			<div class="container">
				<div class="sidebar">
					<admin-menu />
				</div>
				<div class="content">
					<router-view />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderMain from '@/components/HeaderMain'
import AdminMenu from '@/components/AdminMenu'
export default {
	name: 'adminLayout',
	components: { HeaderMain, AdminMenu }
}
</script>

<style lang="sass">
.page-admin
	padding: 47px 0
	.container
		display: flex
		gap: 0 30px
	.sidebar
		flex-shrink: 0
		width: 263px
	.content
		width: calc(100% - 263px)

@media only screen and (max-width : 1000px)
	.page-admin
		.container
			flex-direction: column
			gap: 30px 0
		.sidebar
			width: 100%
		.content
			width: 100%
</style>